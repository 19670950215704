import {
  AccordionPanel,
  Text,
  Switch,
  DataGrid,
  DataGridHeader,
  DataGridRow,
  DataGridHeaderCell,
  DataGridBody,
  DataGridCell,
  TableColumnDefinition,
  createTableColumn,
  Spinner,
  tokens,
  makeStyles
} from '@fluentui/react-components';
import {CounterInfo, DeviceInfo, Direction, Tag} from '../../types';
import useToasts from '../../hooks/useToasts';
import {usePopulatedTopbarValues} from '@axteams-one/populated-topbar';
import {useOpenTelemetry} from '@axteams-one/opentelemetry-js-react';
import {useEffect, useState} from 'react';
import {assignTag, disableReporting, enableReporting, unassignTag} from '../../fetcher';
import {directionString} from '../../helpers/typeConversions';
import {useDataGridStyles} from '../../style/dataGridStyle';
import {TagPicker} from '../../components/TagPicker';

const useStyles = makeStyles({
  spinner: {
    justifyContent: 'center',
    paddingTop: tokens.spacingHorizontalM
  }
});

interface A3DPCPanelProps {
  readonly device: DeviceInfo;
  readonly tags: Tag[];
  readonly enqueueTagRequest: (promise: () => Promise<void>) => void;
}

const A3DPCPanel = ({device, tags, enqueueTagRequest}: A3DPCPanelProps) => {
  const styles = useStyles();
  const gridStyles = useDataGridStyles();
  const {organization} = usePopulatedTopbarValues();
  const openTelemetry = useOpenTelemetry();
  const {dispatchAppToast} = useToasts();
  const [loading, setLoading] = useState<boolean>(false);
  const [counters, setCounters] = useState<CounterInfo[]>();

  useEffect(() => {
    setCounters([
      {
        direction: Direction.In,
        tags: ['In', 'Test'],
        reportingEnabled: false
      },
      {
        direction: Direction.Out,
        tags: ['Out', 'Test'],
        reportingEnabled: false
      }
    ]);
  }, []);

  if (!organization || !counters) {
    return <Spinner className={styles.spinner} />;
  }

  const columns: TableColumnDefinition<CounterInfo>[] = [
    createTableColumn<CounterInfo>({
      renderHeaderCell: () => <Text className={gridStyles.header}>Direction</Text>,
      renderCell: counter => directionString(counter.direction),
      columnId: 'direction'
    }),
    createTableColumn<CounterInfo>({
      renderHeaderCell: () => <Text className={gridStyles.header}>Tags</Text>,
      renderCell: counter => (
        <TagPicker
          allOptions={tags.map(tag => tag.name)}
          selectedOptions={counter.tags}
          onUnassign={tagName => {
            enqueueTagRequest(() =>
              unassignTag(
                {
                  organizationArn: organization.arn,
                  tagName,
                  scenarioId: counter.direction,
                  serial: device.serial
                },
                openTelemetry
              ).then(success => {
                if (!success) {
                  dispatchAppToast({
                    title: 'Unexpected Error',
                    intent: 'error',
                    message: 'Failed to unassign tag, please reload the page'
                  });
                }
              })
            );
            setCounters(counters =>
              counters?.map(c => {
                if (c.direction === counter.direction) {
                  return {...c, tags: c.tags.filter(t => t !== tagName)};
                }
                return c;
              })
            );
          }}
          onAssign={tagName => {
            enqueueTagRequest(() =>
              assignTag(
                {
                  organizationArn: organization.arn,
                  tagName,
                  scenarioId: counter.direction,
                  serial: device.serial
                },
                openTelemetry
              ).then(success => {
                if (!success) {
                  dispatchAppToast({
                    title: 'Unexpected Error',
                    intent: 'error',
                    message: 'Failed to assign tag, please reload the page'
                  });
                }
              })
            );
            setCounters(counters =>
              counters?.map(c => {
                if (c.direction === counter.direction) {
                  return {...c, tags: [...c.tags, tagName]};
                }
                return c;
              })
            );
          }}
          id={counter.direction}
        />
      ),
      columnId: 'tags'
    }),
    createTableColumn<CounterInfo>({
      renderHeaderCell: () => <Text className={gridStyles.header}>Report</Text>,
      renderCell: counter =>
        loading ? (
          <Spinner className={styles.spinner} size="extra-small" />
        ) : (
          <Switch
            data-testid={counter.direction + '-report-switch'}
            disabled={loading}
            checked={counter.reportingEnabled}
            onChange={(_, data) => {
              setLoading(true);
              if (data.checked) {
                enableReporting(
                  {
                    organizationId: organization.id,
                    serial: device.serial
                  },
                  openTelemetry
                )
                  .then(success => {
                    if (success) {
                      setCounters(counters =>
                        counters?.map(c => {
                          return {...c, reportingEnabled: true};
                        })
                      );
                      dispatchAppToast({
                        title: 'Success',
                        intent: 'success',
                        message: `Successfully enabled reporting`
                      });
                    } else {
                      dispatchAppToast({
                        title: 'Error',
                        intent: 'error',
                        message: 'Failed to enable reporting'
                      });
                    }
                  })
                  .finally(() => setLoading(false));
              } else {
                disableReporting(
                  {
                    organizationId: organization.id,
                    serial: device.serial
                  },
                  openTelemetry
                )
                  .then(success => {
                    if (success) {
                      setCounters(counters =>
                        counters?.map(c => {
                          return {...c, reportingEnabled: false};
                        })
                      );
                      dispatchAppToast({
                        title: 'Success',
                        intent: 'success',
                        message: `Successfully disabled reporting`
                      });
                    } else {
                      dispatchAppToast({
                        title: 'Error',
                        intent: 'error',
                        message: 'Failed to disable reporting'
                      });
                    }
                  })
                  .finally(() => setLoading(false));
              }
            }}
          />
        ),
      columnId: 'report'
    })
  ];

  return (
    <AccordionPanel>
      <DataGrid
        className={gridStyles.root}
        items={counters || []}
        columns={columns}
        data-testid="counter-table"
        columnSizingOptions={{tags: {defaultWidth: 400}}}
        resizableColumns
      >
        <DataGridHeader>
          <DataGridRow>
            {({renderHeaderCell}) => <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>}
          </DataGridRow>
        </DataGridHeader>
        <DataGridBody<CounterInfo>>
          {({item, rowId}) => (
            <DataGridRow<CounterInfo> className={gridStyles.row} key={rowId}>
              {({renderCell}) => <DataGridCell>{renderCell(item)}</DataGridCell>}
            </DataGridRow>
          )}
        </DataGridBody>
      </DataGrid>
    </AccordionPanel>
  );
};

export default A3DPCPanel;
