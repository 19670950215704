import {ACAPStatus, Direction} from '../types';

export const nullToUndefined = <T>(obj: T | null): T | undefined => {
  if (obj === null) {
    return undefined;
  }
  return obj;
};

export const ACAPStatusEnum = (status: string): ACAPStatus => {
  if (status === 'NotInstalled') {
    return ACAPStatus.NotInstalled;
  } else if (status === 'Running') {
    return ACAPStatus.Running;
  } else if (status === 'Stopped') {
    return ACAPStatus.Stopped;
  }
  throw new Error('No enum variant for status: ' + status);
};

export const directionString = (direction: Direction) => {
  switch (direction) {
    case Direction.In:
      return 'In';
    case Direction.Out:
      return 'Out';
  }
};
